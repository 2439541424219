($ => {
  var s;
  extend(_e1, {
    /**
     * Reusable site resize function.
     * @namespace viewportResize
     */
    viewportResize: {
      settings: {
        resizeTimeout: null,
        timeoutDuration: 200
      },

      /**
       * Initialises viewport resize module, binds event to window resize.
       * @function init
       * @memberOf viewportResize
       */
      init: function() {
        s = this.settings;
        var self = this;

        _e1.settings.$window.on("resize.viewportResize", function() {
          // //console.log('viewportSize:', {
          // 	width: window.innerWidth,
          // 	height: window.innerHeight
          // });

          if (s.resizeTimeout) {
            clearTimeout(s.resizeTimeout);
          }

          $.publish("viewportResizeStart");

          s.resizeTimeout = setTimeout(function() {
            $.publish("viewportResizeEnd_prioritize");
            $.publish("viewportResizeEnd");
          }, s.timeoutDuration);
        });
      }
    }
  });

  $.subscribe("pageReady", function() {
    _e1.viewportResize.init();
  });
})(jQuery);
