($ => {
  extend(_e1, {
    slider: {
      // DOM Objects
      _sliders: null,
      _slides: null,

      // Selectors
      $slider: ".js-slider",
      $slides: ".js-slider__slides",
      $prevButton: ".c-slider__prev",
      $nextButton: ".c-slider__next",

      // Classes
      activeClass: "is-active",

      // Misc
      slickSettings: {
        arrows: false,
        fade: true
      },

      init: function() {
        var self = this;

        self._sliders = document.querySelectorAll(self.$slider);

        if (!self._sliders) {
          return;
        }

        for (let el = 0; el < self._sliders.length; el++) {
          const _slider = self._sliders[el];
          var _slides = _slider.querySelector(self.$slides);
          $(_slides).slick(self.slickSettings);
          self.bindEvents(_slider, _slides);
        }
      },

      bindEvents: function(_slider, _slides) {
        var self = this;
        var _prevButton = _slider.querySelector(self.$prevButton);
        var _nextButton = _slider.querySelector(self.$nextButton);

        _prevButton.addEventListener(
          "click",
          self.prevSlide.bind(this, _slides)
        );
        _nextButton.addEventListener(
          "click",
          self.nextSlide.bind(this, _slides)
        );
      },

      prevSlide: function(_slides, e) {
        e.preventDefault();
        $(_slides).slick("slickPrev");
      },

      nextSlide: function(_slides, e) {
        e.preventDefault();
        $(_slides).slick("slickNext");
      }
    }
  });

  $.subscribe("pageLoaded", function() {
    _e1.slider.init();
  });
})(jQuery);
