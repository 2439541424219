/**
 *  Accordion Component
 */
($ => {
  let s;
  extend(_e1, {
    accordion: {
      settings: {
        // DOM Objects
        _accordions: null,
        _headings: null,

        // Selectors
        accordionSelector: ".c-accordion",
        headingSelector: ".c-accordion__heading",

        // Classes
        activeClass: "is-active",
        openClass: "is-open"

        // Misc
        // ...
      },

      init: function() {
        s = this.settings;
        var self = this;

        s._accordions = document.querySelectorAll(s.accordionSelector);

        if (!s._accordions) {
          return;
        }

        s._headings = document.querySelectorAll(s.headingSelector);

        if (s._headings.length >= 1) {
          self.bindEvents();
        }
      },

      bindEvents: function() {
        var self = this;

        for (let el = 0; el < s._headings.length; el++) {
          const element = s._headings[el];
          element.addEventListener("click", self.toggle.bind(this));
        }
      },

      toggle: function(e) {
        e.preventDefault();

        const isOpen = _e1.hasClass(e.target, "is-open");
        isOpen
          ? _e1.removeClass(e.target, "is-open")
          : _e1.addClass(e.target, "is-open");
      }
    }
  });

  $.subscribe("pageReady", function() {
    _e1.accordion.init();
  });
})(jQuery);
