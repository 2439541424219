($ => {
  extend(_e1, {
    langSelector: {
      // DOM Objects
      _element: null,

      // Selectors
      $element: "google_translate_element",

      init: function() {
        var self = this;

        self._element = document.getElementById(self.$element);

        if (!self._element) {
          return;
        } else {
          new google.translate.TranslateElement(
            {
              pageLanguage: "en",
              layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
              autoDisplay: false
            },
            "google_translate_element"
          );
        }
      }
    }
  });

  $.subscribe("pageLoaded", function() {
    _e1.langSelector.init();
  });
})(jQuery);
