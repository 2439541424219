($ => {
  let s;
  extend(_e1, {
    primaryNav: {
      settings: {
        // DOM Objects
        _primaryNav: null,
        _navMenu: null,
        _navToggles: null,
        _menuLists: null,
        _menuItems: null,
        _dropdownBtns: null,

        // Selectors
        $primaryNav: ".c-primary-nav",
        $navMenu: ".c-primary-nav",
        $navToggle: ".js-primary-nav__toggle",
        $menuList: ".c-primary-nav__list",
        $menuItem: ".c-primary-nav__item",
        $dropdownBtn: ".c-primary-nav__dropdown-btn",
        $menuAim: ".js-menu-aim",

        // Classes
        activeClass: "is-active",
        openClass: "is-open",
        childrenClass: "has-children",

        // Misc
        // ...
        isOpen: false
      },

      init: function() {
        s = this.settings;
        let self = this;

        s._primaryNav = document.querySelector(s.$primaryNav);
        s._navMenu = document.querySelector(s.$navMenu);

        s._navToggles = document.querySelectorAll(s.$navToggle);
        s._menuLists = document.querySelectorAll(s.$menuList);
        // s._menuAim = document.querySelector(s.$menuAim);
        s._menuItems = document.querySelectorAll(s.$menuItem);
        s._dropdownBtns = document.querySelectorAll(s.$dropdownBtn);

        if (!s._primaryNav) {
          return;
        }

        if (s._primaryNav) {
          self.bindEvents();
        }

        $(s.$menuAim).menuAim({
          activate: self.activateSubmenu,
          deactivate: self.deactivateSubmenu
        });
      },

      activateSubmenu: function(row) {
        console.log("activateSubmenu: ", { row });
        _e1.addClass(row, "js-menu-aim--active");
      },

      deactivateSubmenu: function(row) {
        console.log("deactivateSubmenu: ", { row });
        _e1.removeClass(row, "js-menu-aim--active");
      },

      bindEvents: function() {
        var self = this;

        if (s._navToggles.length) {
          for (let el = 0; el < s._navToggles.length; el++) {
            const element = s._navToggles[el];
            element.addEventListener("click", self.toggle.bind(this));
          }
        }

        if (s._menuItems.length) {
          for (let el = 0; el < s._menuItems.length; el++) {
            const element = s._menuItems[el];
            element.addEventListener("click", self.menuItemClick.bind(this));
          }
        }
      },

      toggle: function(e) {
        e.preventDefault();
        var self = this;
        s.isOpen ? self.close() : self.open();
      },

      open: function() {
        s._navMenu.classList.add(s.openClass);
        s.isOpen = true;
      },

      close: function() {
        s._navMenu.classList.remove(s.openClass);
        s.isOpen = false;
        this.reset();
      },

      toggleSubMenu: function(el) {
        const isOpen = _e1.hasClass(el, s.openClass);
        isOpen
          ? _e1.removeClass(el, s.openClass)
          : _e1.addClass(el, s.openClass);
      },

      menuItemClick: function(e) {
        //console.log(e.target);
        let self = this;
        e.stopPropagation();
        const isDropdownBtn = _e1.hasClass(
          e.target,
          "c-primary-nav__dropdown-btn"
        );
        const isIcon = _e1.hasClass(e.target, "c-icon");

        if (isIcon || isDropdownBtn) {
          e.preventDefault();
          const $menuItem = _e1.closestClass(e.target, "c-primary-nav__item");
          self.toggleSubMenu($menuItem);
        } else {
          return;
        }
      },

      reset: function() {
        for (let el = 0; el < s._menuItems.length; el++) {
          const element = s._menuItems[el];
          element.classList.remove(s.openClass);
        }
      }
    }
  });

  $.subscribe("pageReady", function() {
    _e1.primaryNav.init();
  });
})(jQuery);
