($ => {
  /**
   * Publish events using Pub/Sub
   * @namespace events
   * @see {@link https://github.com/cowboy/jquery-tiny-pubsub}
   */
  extend(_e1, {
    /**
     * Publish event when the page is ready.
     * @function pageReady
     */
    pageReady: function() {
      var self = this;

      $.publish("pageReady_prioritize", self);
      $.publish("pageReady", self);

      _e1.log(" Page is ready! ", "background:#000;color:#FFF;");

      var root = document.documentElement;

      _e1.removeClass(root, "no-js");

      self.pageLoaded();
    },

    /**
     * Publish event when the page has loaded.
     * @function pageLoaded
     */
    pageLoaded: function() {
      var self = this;

      self.settings.$window.on("load", function() {
        $.publish("pageLoaded", self);
        _e1.log(" Page is loaded! ", "background:#F00;color:#FF0;");

        // _e1.primaryNav.close();
      });
    },

    /**
     * Publish event when an AJAX request has finished.
     * @function ajaxLoaded
     */
    ajaxLoaded: function() {
      var self = this;

      $.publish("ajaxLoaded", self);
      _e1.log(" AJAX loaded! ", "background:#F00;color:#FF0;");
    }
  });
})(jQuery);
