(($) => {
  let s;
  extend(_e1, {
    homeHero: {
      settings: {
        // DOM Objects
        _homeHero: null,
        _slider: null,
        _slides: null,
        _prevButton: null,
        _nextButton: null,

        // Selectors
        $homeHero: '.js-home-hero',
        $slider: '.c-home-hero__slider',
        $slides: '.c-home-hero__slide',
        $prevButton: '.c-slider__prev',
        $nextButton: '.c-slider__next',

        // Classes
        activeClass: 'is-active',

        // Misc
        slickSettings: {
          arrows: false,
          fade: true,
          dots: true,
          dotsClass: 'c-home-hero__dots',
        },
      },

      init: function () {
        s = this.settings;
        let self = this;

        s._homeHero = document.querySelector(s.$homeHero);

        if (!s._homeHero) {
          return;
        }

        s._slider = document.querySelector(s.$slider);
        s._slides = document.querySelectorAll(s.$slides);
        s._prevButton = document.querySelector(s.$prevButton);
        s._nextButton = document.querySelector(s.$nextButton);

        // Init slick.js
        $(s._slider).slick(s.slickSettings);

        self.bindEvents();
      },

      bindEvents: function () {
        var self = this;

        s._prevButton.addEventListener('click', self.prevSlide.bind(this));
        s._nextButton.addEventListener('click', self.nextSlide.bind(this));
      },

      prevSlide: function (e) {
        $(s._slider).slick('slickPrev');
      },

      nextSlide: function (e) {
        $(s._slider).slick('slickNext');
      },
    },
  });

  $.subscribe('pageReady', function () {
    _e1.homeHero.init();
  });

  if (!($('.js-home-hero .slick-slide').length > 1)) {
    // remove arrows
    $('.c-home-hero__controls').hide();
  }
})(jQuery);
