($ => {
  let s;
  extend(_e1, {
    gallery: {
      settings: {
        // DOM Objects
        _gallery: null,
        _slider: null,
        _slides: null,
        _prevButton: null,
        _nextButton: null,

        // Selectors
        $gallery: ".js-gallery",
        $slider: ".c-gallery__slider",
        $slides: ".c-gallery__slide",
        $prevButton: ".c-gallery__prev",
        $nextButton: ".c-gallery__next",

        // Classes
        activeClass: "is-active",

        // Misc
        slickSettings: {
          arrows: false,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 1000,
              settings: {
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                lazyLoad: "ondemand"
              }
            }
          ]
        }
      },

      init: function() {
        s = this.settings;
        let self = this;

        s._gallery = document.querySelector(s.$gallery);

        if (!s._gallery) {
          return;
        }

        s._slider = document.querySelector(s.$slider);
        s._slides = document.querySelectorAll(s.$slides);
        s._prevButton = document.querySelector(s.$prevButton);
        s._nextButton = document.querySelector(s.$nextButton);

        // Init slick.js
        $(s._slider).slick(s.slickSettings);

        self.bindEvents();
      },

      bindEvents: function() {
        var self = this;

        s._prevButton.addEventListener("click", self.prevSlide.bind(this));
        s._nextButton.addEventListener("click", self.nextSlide.bind(this));
      },

      prevSlide: function(e) {
        $(s._slider).slick("slickPrev");
      },

      nextSlide: function(e) {
        $(s._slider).slick("slickNext");
      }
    }
  });

  $.subscribe("pageReady", function() {
    _e1.gallery.init();
  });
})(jQuery);
