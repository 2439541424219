($ => {
  extend(_e1, {
    /**
     * Returns a query string parameter’s value if specified, object of query string parameters if not.
     * @function getUrlParams
     * @memberof utilities
     * @param {String} [parameter] Parameter passed in to retrieve from query string
     * @returns {Obj} [params] | {String} [param]
     */
    getUrlParams: function(parameter) {
      var queryString = window.location.search;

      if (queryString !== undefined) {
        queryString = window.location.search.replace("?", "");

        var params = {},
          queryStringArray = queryString.split("&");

        for (var index in queryStringArray) {
          var query = queryStringArray[index].split("=");

          params[decodeURIComponent(query[0])] = decodeURIComponent(query[1]);
        }

        if (parameter) {
          return params[parameter];
        } else {
          return params;
        }
      }
    },

    setUrlParams: function() {
      var self = this;
      self.settings.urlParams = self.getUrlParams(window.location.search);
    },
    /*
		 * Safely outputs message to browser console. Use for debugging/logging.
		 * @function log
		 * @param {String|Object} content - Content to log to browser console.
		 * @param {String} styles - CSS style to apply to text logged to browser console.
		 * @example
		 * _e1.log('Hello, World!', 'background:#F00;color:#FF0;');
		 */
    log: function(content, style) {
      if (typeof console !== "undefined") {
        if (style) {
          //console.log("%c" + content, style);
        } else {
          //console.log(content);
        }
      }
    },

    htmlEncode: function(value) {
      if (value) {
        return $("<div />")
          .text(value)
          .html();
      } else {
        return "";
      }
    },

    htmlDecode: function(value) {
      if (value) {
        return $("<div />")
          .html(value)
          .text();
      } else {
        return "";
      }
    },

    // get IE version from classname (acceptable values: 10,9,8 or 7)
    ltIE: function(version) {
      var self = this;
      if (self.settings.$html.hasClass("lt-ie" + version)) {
        return true;
      } else {
        return false;
      }
    },

    browserPrefix: function() {
      if (window.getComputedStyle) {
        var self = this,
          styles = window.getComputedStyle(window.document.documentElement, ""),
          prefix = (Array.prototype.slice
            .call(styles)
            .join("")
            .match(/-(moz|webkit|ms)-/) ||
            (styles.OLink === "" && ["", "o"]))[1];
        self.settings.browserPrefix = "-" + prefix + "-";
      }
    },

    transitionAnimationEndEvent: function() {
      var self = this,
        transition,
        transitions,
        animation,
        animations,
        element = window.document.createElement("transitionAnimationElement");
      transitions = {
        WebkitTransition: "webkitTransitionEnd",
        MozTransition: "transitionend",
        MSTransition: "msTransitionEnd",
        OTransition: "oTransitionEnd",
        transition: "transitionend"
      };
      animations = {
        WebkitAnimation: "webkitAnimationEnd",
        MozAnimation: "animationend",
        MSAnimation: "msAnimationEnd",
        OAnimation: "oAnimationEnd",
        animation: "animationend"
      };
      for (transition in transitions) {
        if (element.style[transition] !== undefined) {
          self.settings.transitionEnd = transitions[transition];
        }
      }
      // is it null?
      if (self.settings.transitionEnd === null) {
        self.settings.transitionEnd = "noTransitionEnd";
      }
      for (animation in animations) {
        if (element.style[animation] !== undefined) {
          self.settings.animationEnd = animations[animation];
        }
      }
      // is it null?
      if (self.settings.animationEnd === null) {
        self.settings.animationEnd = "noAnimationEnd";
      }
      self.settings.transitionAnimationEnd = (
        self.settings.transitionEnd +
        " " +
        self.settings.animationEnd
      ).toString();
    },

    textDirection: function() {
      var self = this,
        direction = self.settings.$html.attr("dir");
      if (direction === "rtl") {
        self.settings.rtl = true;
      }
    },

    closestClass: function(el, className) {
      while (el) {
        if (_e1.hasClass(el, className)) {
          break;
        }
        el = el.parentElement;
      }
      return el;
    },

    hasClass: function(el, className) {
      if (el.classList) {
        return el.classList.contains(className);
      } else {
        if (el.className) {
          var classArray = el.className.split(" ");
          for (var i = 0; i < classArray.length; i++) {
            if (classArray[i] === className) {
              return true;
            }
          }
        }
      }
      //return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
    },

    addClass: function(el, className) {
      var classNames = className.split(" ");
      var i;

      for (i = 0; i < classNames.length; i++) {
        if (el.classList) {
          el.classList.add(classNames[i]);
        } else if (!_e1.hasClass(el, classNames[i])) {
          el.className += " " + classNames[i];
        }
      }
    },

    removeClass: function(el, className) {
      var classNames = className.split(" ");
      var i;

      for (i = 0; i < classNames.length; i++) {
        if (el.classList) {
          el.classList.remove(classNames[i]);
        } else {
          el.className = el.className.replace(
            new RegExp("\\b" + classNames[i] + "\\b", "g"),
            ""
          );
        }
      }
    }
  });

  $.subscribe("pageReady", function() {
    _e1.textDirection();
    _e1.browserPrefix();
    _e1.transitionAnimationEndEvent();
    _e1.setUrlParams();
  });
})(jQuery);
